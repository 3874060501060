import React, { DependencyList } from 'react'
import { useDispatch } from 'react-redux'

const useAction = <T extends (...args: any) => any>(
  actionFn: T,
  deps: DependencyList = [],
) => {
  const dispatch = useDispatch()
  return React.useCallback(
    (...args: Parameters<T>): ReturnType<T> =>
      dispatch(actionFn(...(args as any))) as any,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps, dispatch],
  )
}

export default useAction
