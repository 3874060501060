import React, { useEffect } from 'react'
import { modals } from '~/constants/modals'
import useAction from '~/hooks/useAction'
import { useAppSelector } from '~/store/hooks'
import dynamic from 'next/dynamic'
import { actions } from '~/store/actions'

export type ModalMap = {
  [key in ValueOf<typeof modals>]: React.FC<any> | React.ComponentType<any>
}

export const modalsMap: any = {
  BasicModal: dynamic(
    () => import('~/containers/BasicModalContainer/BasicModalContainer'),
  ),
  SelectBranchsModal: dynamic(
    () => import('~/containers/SelectBranchModalContainer'),
  ),
}

const RootModal = () => {
  const { openedName, closingName } = useAppSelector((state) => state.modal)
  const dispatchClearModal = useAction(actions.modal.clear)

  useEffect(() => {
    if (closingName) {
      const timeoutId = setTimeout(() => {
        dispatchClearModal()
      }, 150)
      return () => clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closingName])

  return (
    <>
      {(() => {
        const Modal = openedName && modalsMap[openedName]
        if (Modal) {
          return <Modal key={openedName} />
        }
        if (openedName && !Modal) {
          console.error('Cannot render opened modal by key', openedName)
        }
        return null
      })()}


      {(() => {
        const Modal = closingName && modalsMap[closingName]
        if (Modal && closingName !== openedName) {
          return <Modal key={closingName} />
        }
        if (closingName && !Modal) {
          console.error('Cannot render closing modal by key', closingName)
        }
        return null
      })()}
    </>
  )
}
export default RootModal
